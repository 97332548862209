import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Select,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Card,
  CardBody,
  Center,
  Avatar,
  AvatarBadge,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { CREATE_USER, GET_ALL_USERS } from '../../config/graphql';
import Error from '../Error';
import axios from 'axios';

function CreateUsers({ onClose, isOpen }) {
  const [user, setUser] = useState({
    password: '12345678Bmp',
    country: 'Burundi',
    city: 'Bujumbura',
  });
  function CloseModal() {
    onClose = { onClose };
  }
  const handleOnChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
    console.log(user);
  };

  const [createUser, { loading, error, data }] = useMutation(CREATE_USER, {
    refetchQueries: [{ query: GET_ALL_USERS }],
  });
  // ============UPLOAD FILES=====================
  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';

  const [loadingFile, setLoadingFile] = useState(false);
  const [fileError, setFileError] = useState();

  const uploadFileHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      alert('No File added!');
    } else {
      try {
        setLoadingFile(true);
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post(`${imageUrl}/upload`, formData);

        if (response.status === 200) {
          const { path } = response.data;
          console.log('File uploaded successfully:', path);
          setFileError(null);
          setUser({ ...user, imageUrl: path });
        } else {
          console.error('File upload failed');
          setFileError('500: Server error!');
          // Handle error, e.g., show an error message or update UI accordingly
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setFileError(error);
      } finally {
        setLoadingFile(false);
      }
    }
  };

  const handleOnSubmit = e => {
    createUser({ variables: { ...user } })
      .catch(e => console.log(e))
      .then(data => {
        onClose = { onClose };
      });
    e.preventDefault();
  };
  return (
    <>
      <Modal
        isCentered
        size={'sm'}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ADD NEW USER</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Card>
              <CardBody>
                <FormControl id="userName">
                  <FormLabel>User Picture</FormLabel>
                  <Stack direction={['column', 'row']} spacing={6}>
                    <Center>
                      {/* <Avatar size="xl" src="https://bit.ly/sage-adebayo"> */}
                      {/* <AvatarBadge
                          as={IconButton}
                          size="sm"
                          rounded="full"
                          top="-10px"
                          colorScheme="red"
                          aria-label="remove Image"
                          icon={<SmallCloseIcon />}
                        /> */}
                      {/* </Avatar> */}
                      {user.imageUrl ? (
                        <Avatar size="xl" src={imageUrl + user?.imageUrl} />
                      ) : (
                        <Avatar size="xl" name={'Picture'} />
                      )}

                      {fileError ? <Text>{fileError.message}</Text> : <></>}
                    </Center>
                    <Center w="full">
                      <VStack>
                        <Input
                          isDisabled={loadingFile}
                          pt={1}
                          accept="image/*"
                          onChange={uploadFileHandler}
                          name="imageUrl"
                          type="file"
                        />
                      </VStack>
                    </Center>
                  </Stack>
                </FormControl>
                <FormControl id="firstName" isRequired mt={2}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    name="name"
                    placeholder="Arsene Rema"
                    _placeholder={{ color: 'gray.500' }}
                    type="text"
                    onChange={handleOnChange}
                  />
                </FormControl>

                <FormControl id="phone" isRequired mt={'2'}>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    name="phone"
                    placeholder="69000289"
                    _placeholder={{ color: 'gray.500' }}
                    type="text"
                    onChange={handleOnChange}
                  />
                </FormControl>

                <FormControl id="email" isRequired mt={'2'}>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    name="email"
                    placeholder="your-email@example.com"
                    _placeholder={{ color: 'gray.500' }}
                    type="email"
                    onChange={handleOnChange}
                  />
                </FormControl>

                <FormControl id="role" isRequired mt={'2'}>
                  <FormLabel>Role</FormLabel>
                  <Select
                    placeholder="Select Role"
                    name="role"
                    onChange={handleOnChange}
                  >
                    {/* <option value="seller">seller</option> */}
                    <option value="editor">editor</option>
                    {/* <option value="user">user</option> */}
                    <option value="admin">admin</option>
                    {/* <option value="superAdmin">superAdmin</option> */}
                  </Select>
                </FormControl>
                {/* {user.role === 'seller' ? (
                  <FormControl id="bussinesName" isRequired mt={'2'}>
                    <FormLabel>Bussines Name</FormLabel>
                    <Input
                      name="Bussines Name"
                      placeholder="Umuco Restaurant "
                      _placeholder={{ color: 'gray.500' }}
                      type="text"
                      onChange={handleOnChange}
                    />
                  </FormControl>
                ) : (
                  <></>
                )} */}

                <Stack spacing={6} direction={['column', 'row']}>
                  <Button
                    isLoading={loading}
                    bg={'brand.primary'}
                    color={'white'}
                    w="full"
                    _hover={{
                      bg: 'brand.hoverPrimary',
                    }}
                    loadingText="Submitting"
                    variant="outline"
                    mt={'2'}
                    mb={2}
                    onClick={handleOnSubmit}
                  >
                    Submit
                  </Button>
                </Stack>
                {!error ? <></> : <Error data={error} />}
              </CardBody>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateUsers;
