import React, { useRef } from 'react';
import {
  Badge,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue,
  HStack,
  Card,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { GET_USER } from '../../config/graphql';
import Loading from '../Loading';
import Error from '../Error';
import { useParams } from 'react-router-dom';
import { Timer2 } from '../../config/functions';

function UserDetails() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id },
  });
  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';

  const stackBg = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.700', 'gray.400');

  return (
    <>
      <HStack justifyContent={'space-between'}>
        <Heading size={'md'}>Details on User {data?.user?.name}</Heading>
      </HStack>
      <br />
      {loading ? (
        <Loading />
      ) : error ? (
        <Error data={error} />
      ) : (
        <Center py={{ base: 5, md: 10 }}>
          <Stack
            borderWidth="1px"
            borderRadius="lg"
            w={{ sm: '100%', md: '540px' }}
            height={{ sm: '476px', md: '20rem' }}
            direction={{ base: 'column', md: 'row' }}
            bg={stackBg}
            boxShadow={'2xl'}
            padding={4}
          >
            <Flex flex={1}>
              <Image
                objectFit="cover"
                boxSize="100%"
                src={imageUrl + data?.user?.imageUrl}
                alt="No User Image"
              />
            </Flex>
            <Stack
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              // p={1}
              // pt={2}
            >
              <Heading fontSize={'2xl'} fontFamily={'body'}>
                {data?.user?.name}
              </Heading>
              <Stack align={'center'} justify={'center'} direction={'row'}>
                <Badge
                  // px={2}
                  // py={1}

                  // bg={useColorModeValue('gray.50', 'gray.800')}
                  // fontWeight={'400'}
                  colorScheme={'blue'}
                >
                  {data?.user?.role}
                </Badge>
              </Stack>
              <Text fontWeight={600} color={'gray.500'} size="sm">
                {data?.user?.email}
              </Text>
              <Text fontWeight={600} color={'gray.500'} size="sm">
                {data?.user?.phone}
              </Text>
              <Text textAlign={'center'} color={textColor} px={3}>
                {data?.user?.country}, {data?.user?.city}, {data?.user?.address}
              </Text>
              <Text textAlign={'center'} color={textColor} px={3}>
                {data?.user?.birthdate}
              </Text>
              <Text textAlign={'center'} color={textColor} px={3}>
                {data?.user?.gender === 'not-specified' ? (
                  <></>
                ) : (
                  <>{data?.user?.gender}</>
                )}
              </Text>
              <Text textAlign={'center'} color={textColor} px={3}>
                {Timer2(data?.user?.createdAt)}
              </Text>
            </Stack>
          </Stack>
        </Center>
      )}
    </>
  );
}

export default UserDetails;
