import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Card,
  CardBody,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { CREATE_VARIATION, GET_PRODUCT } from '../../config/graphql';
import Error from '../Error';

function OptionsModal(props) {
  const id = props.data.id;
  const [variation, setVariation] = useState('');
  function CloseModal() {
    props.setOpen(false);
  }
  const [addVariation, { loading, error }] = useMutation(CREATE_VARIATION, {
    refetchQueries: [{ query: GET_PRODUCT, variables: { id } }],
  });
  const handleOnChange = e => {
    setVariation({ ...variation, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = e => {
    variation.productId = id;
    if (variation.qty) variation.qty = parseInt(variation.qty);
    if (variation.priceInEUR)
      variation.priceInEUR = parseFloat(variation.priceInEUR);
    if (variation.priceInUSD)
      variation.priceInUSD = parseFloat(variation.priceInUSD);
    if (variation.priceInBIF)
      variation.priceInBIF = parseFloat(variation.priceInBIF);

    addVariation({ variables: { ...variation } })
      .then(data => {
        CloseModal();
      })
      .catch(e => console.log(e));
    e.preventDefault();
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={() => props.setOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ADD OPTIONS</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Card>
              <CardBody>
                <FormControl id="name" isRequired mt={'2'}>
                  <FormLabel> Name</FormLabel>
                  <Input
                    name="name"
                    placeholder="size"
                    _placeholder={{ color: 'gray.500' }}
                    type="text"
                    onChange={handleOnChange}
                  />
                </FormControl>

                <FormControl id="qty" isRequired mt={'2'}>
                  <FormLabel>Quantity</FormLabel>
                  <Input
                    name="qty"
                    placeholder="10"
                    _placeholder={{ color: 'gray.500' }}
                    type="text"
                    onChange={handleOnChange}
                  />
                </FormControl>

                <FormControl id="priceInBIF" mt={'2'}>
                  <FormLabel>Price In BIF</FormLabel>
                  <InputGroup>
                    <Input
                      name="priceInBIF"
                      placeholder="5000"
                      _placeholder={{ color: 'gray.500' }}
                      type="text"
                      onChange={handleOnChange}
                    />
                    <InputRightAddon>BIF</InputRightAddon>
                  </InputGroup>
                </FormControl>
                <FormControl id="priceInUSD" mt={'2'}>
                  <FormLabel>Price In USD</FormLabel>
                  <InputGroup>
                    <Input
                      name="priceInUSD"
                      placeholder="5000"
                      _placeholder={{ color: 'gray.500' }}
                      type="text"
                      onChange={handleOnChange}
                    />
                    <InputRightAddon>USD</InputRightAddon>
                  </InputGroup>
                </FormControl>
                <FormControl id="priceInEUR" mt={'2'}>
                  <FormLabel>Price In EUR</FormLabel>
                  <InputGroup>
                    <Input
                      name="priceInEUR"
                      placeholder="5000"
                      _placeholder={{ color: 'gray.500' }}
                      type="text"
                      onChange={handleOnChange}
                    />
                    <InputRightAddon>EUR</InputRightAddon>
                  </InputGroup>
                </FormControl>

                <Stack spacing={6} direction={['column', 'row']} mt={2}>
                  <Button
                    isLoading={loading}
                    bg={'brand.primary'}
                    color={'white'}
                    w="full"
                    _hover={{
                      bg: 'brand.hoverPrimary',
                    }}
                    loadingText="Submitting"
                    variant="outline"
                    mt={'2'}
                    mb={2}
                    onClick={handleOnSubmit}
                  >
                    Submit
                  </Button>
                </Stack>
                {!error ? <></> : <Error data={error} />}
              </CardBody>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default OptionsModal;
