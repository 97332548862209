import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  return user;
};

const ProtectedRoutes = ({ children }) => {
  const location = useLocation();
  const isAuth = useAuth();
  return isAuth ? children : <Navigate to="/" />;
};

const ProtectedRouteLogin = ({ children }) => {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    return <Navigate to="/dashboard" />;
  } else {
    return children;
  }
};

export { ProtectedRouteLogin };

export default ProtectedRoutes;
