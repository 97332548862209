import React, { useRef } from 'react';
import {
  Table,
  TableContainer,
  Tr,
  Thead,
  Tbody,
  Td,
  Card,
  CardBody,
  HStack,
  Heading,
  Stack,
  Button,
  Image,
  CardHeader,
  Th,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react';
import { FiPrinter } from 'react-icons/fi';
import ReactToPrint from 'react-to-print';
import '../print.css';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ORDER } from '../../config/graphql';
import Loading from '../Loading';
import Error from '../Error';
import logo from '../../assets/logo.png';
import { Timer2 } from '../../config/functions';

function OrderDetails() {
  const ref = useRef();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_ORDER, { variables: { id } });

  if (loading) return <Loading />;
  if (error) return <Error data={error} />;

  return (
    <>
      <HStack justifyContent={'space-between'}>
        <Image cursor={'pointer'} width={100} src={logo} alt="logo" />
        <Heading size={'md'}>
          Details On Order {data.order?.orderId} By {data.order?.createdBy.name}
        </Heading>

        <Stack direction={['column', 'row']} spacing={2}>
          <ReactToPrint
            bodyClass="print-agreement"
            content={() => ref.current}
            trigger={() => (
              <Button
                size={'sm'}
                color={'white'}
                bg={'brand.primary'}
                _hover={{ bg: 'brand.hoverPrimary' }}
              >
                <FiPrinter /> &nbsp; Imprimer
              </Button>
            )}
          />
        </Stack>
      </HStack>
      <br />

      <div ref={ref}>
        <Card>
          <CardHeader>
            <HStack justifyContent={'space-between'}>
              <Image src={logo} width={20} />

              <Text fontWeight={600}>Bujumbura, le {Timer2(new Date())}</Text>
            </HStack>
            {/* INFOS */}
            {/* <Box maxW={'40%'} fontSize={12} fontWeight={600}>
              Future City Technology Market
              <br />
            </Box> */}
            <br />
            {/* VENDOR INFOS AND CUSTOMER INFOS */}
            {/* VENDOR INFOS */}
            <Flex justifyContent={'space-between'}>
              <Box>
                <Heading size={'sm'}>IDENTIFICATION DU VENDEUR</Heading>
                Nom/Raison Social: Future City Technology <br />
                NIF: 4001447277
                <br />
                R\C N°: 24536/20
                <br />
                B.P: 7009 Bujumbura
                <br />
                Telephone: 22 28 14 63/64
                <br />
                Adresse: Bujumbura - Burundi <br />
                Assujetti à la TVA: NO
              </Box>
              <br />
              {/* CUSTOMER INFOS */}
              <Box>
                <Heading size={'sm'}>Le Client</Heading>
                Nom/Raison social: {data.order?.createdBy.name}
                {/* {data?.order.customer.isCompany ? (
                <>
                  <br /> NIF: {data?.order.customer.tin}
                </>
              ) : (
                <></>
              )} */}
                <br />
                Telephone: {data.order?.createdBy.phone}
                <br />
                {/* Adresse: {data?.order.customer.commune} -{' '} */}
                Adresse: {data.order?.createdBy.city} <br />
                Assujetti à la TVA: No
              </Box>
            </Flex>
          </CardHeader>
          <CardBody>
            <Heading size={'md'} textAlign={'center'}>
              Facture No: {data.order?.orderId}
            </Heading>
            <br />
            {/* TABLE */}
            <TableContainer>
              <Table
                size={'sm'}
                marginTop={'2'}
                variant="striped"
                // colorScheme={'brand.table'}
              >
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Qty</Th>
                    <Th>Price</Th>
                    {data.order?.items.option !== null ? (
                      <>
                        <Th>Option Title</Th>
                        <Th>Option Name</Th>
                      </>
                    ) : (
                      <></>
                    )}

                    <Th>Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.order?.items.map(val => (
                    <Tr key={val.id}>
                      <Td>{val.name}</Td>
                      <Td>{val.qty}</Td>
                      <Td>{val.price.toLocaleString('en-FR', {})}</Td>
                      {val.option !== null ? (
                        <>
                          <Td>{val.option.title}</Td>
                          <Td>{val.option.name}</Td>
                        </>
                      ) : (
                        <></>
                      )}

                      <Td>
                        {(val.qty * val.price).toLocaleString('en-FR', {})}
                      </Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Td>
                      <b>PRIX HTVA </b>
                    </Td>
                    {data.order?.items.option !== null ? (
                      <>
                        <Td></Td>
                        <Td></Td>
                      </>
                    ) : (
                      <></>
                    )}
                    <Td></Td>
                    <Td></Td>
                    <Td>
                      <b>
                        {data.order?.amount.toLocaleString('en-FR', {})}{' '}
                        {data.order?.currency}
                      </b>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <b>TVA </b>
                    </Td>
                    {data.order?.items.option !== null ? (
                      <>
                        <Td></Td>
                        <Td></Td>
                      </>
                    ) : (
                      <></>
                    )}
                    <Td></Td>
                    <Td></Td>
                    <Td>
                      <b>
                        {data.order?.vat.toLocaleString('en-FR', {})}{' '}
                        {data.order?.currency}
                      </b>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <b>TOTAL </b>
                    </Td>
                    {data.order?.items.option !== null ? (
                      <>
                        <Td></Td>
                        <Td></Td>
                      </>
                    ) : (
                      <></>
                    )}
                    <Td></Td>
                    <Td></Td>
                    <Td>
                      <b>
                        {data.order?.total.toLocaleString('en-FR', {})}{' '}
                        {data.order?.currency}
                      </b>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            {/* TABLE */}
          </CardBody>
        </Card>
      </div>
      <div>
        {/* ETRA INFOS ON ORDER */}
        <br />
        <Card>
          <CardHeader>
            <Heading size={'md'} textAlign={'center'}>
              More Details On Order {data.order?.orderId} By{' '}
              {data.order?.createdBy.name}
            </Heading>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table variant={'unstyled'}>
                <Thead></Thead>
                <Tbody>
                  <Tr>
                    <Td>Status:</Td>
                    <Td>{data.order?.status}</Td>
                  </Tr>

                  <Tr>
                    <Td>PaymentMethod:</Td>
                    <Td>{data.order?.paymentMethod}</Td>
                  </Tr>
                  {data.order?.paidOn != null ? (
                    <Tr>
                      <Td>Paid On:</Td>
                      <Td>{data.order?.paidOn}</Td>
                    </Tr>
                  ) : (
                    <></>
                  )}

                  {data.order?.deliveredOn != null ? (
                    <Tr>
                      <Td>Delivered On:</Td>
                      <Td>{data.order?.deliveredOn}</Td>
                    </Tr>
                  ) : (
                    <></>
                  )}

                  {data.order?.transactionId != null ? (
                    <Tr>
                      <Td>TransactionId:</Td>
                      <Td>{data.order?.transactionId}</Td>
                    </Tr>
                  ) : (
                    <></>
                  )}

                  <Tr>
                    <Td>Created At:</Td>
                    <Td>{Timer2(data.order?.createdAt)}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default OrderDetails;
