import React, { ReactNode, useState } from 'react';
import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  HStack,
  Input,
  CardBody,
  Card,
  Button,
  Heading,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { BsPerson } from 'react-icons/bs';
import { FiDollarSign } from 'react-icons/fi';
import { FaBarcode } from 'react-icons/fa6';
import { GET_STATISTICS } from '../../config/graphql';
import { useMutation } from '@apollo/client';
import Loading from '../Loading';
import Error from '../Error';

interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
}

function StatsCard(props: StatsCardProps) {
  const { title, stat, icon } = props;

  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}
    >
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

function Stats() {
  const [stat, setStat] = useState('');
  const [stats, { loading, error, data }] = useMutation(GET_STATISTICS);
  const handleOnChange = e => {
    setStat({ ...stat, [e.target.name]: e.target.value });
  };
  const handleOnSubmit = e => {
    stats({ variables: { ...stat } }).catch(e => console.log(e));

    e.preventDefault();
  };

  if (error) return <Error data={error} />;

  return (
    <>
      <HStack justifyContent={'space-between'}>
        <Heading size={'md'}>Statistics</Heading>
      </HStack>
      <br />
      <Card>
        <CardBody>
          <Flex spacing={10} direction={['column', 'row']}>
            <HStack>
              <FormControl isRequired>
                <FormLabel>From</FormLabel>
                <Input
                  type="date"
                  // value={startDate}
                  onChange={handleOnChange}
                  name="from"
                />
              </FormControl>
            </HStack>
            <HStack mt={{ base: 2 }} ml={{ md: 4 }}>
              <FormControl isRequired>
                <FormLabel>To</FormLabel>
                <Input
                  type="date"
                  // value={endDate}
                  onChange={handleOnChange}
                  name="to"
                />
              </FormControl>
            </HStack>
            <Button
              color={'white'}
              bg={'brand.primary'}
              _hover={{
                bg: 'brand.hoverPrimary',
              }}
              mt={{ base: 2, md: 10 }}
              ml={{ md: 4 }}
              onClick={handleOnSubmit}
            >
              Search
            </Button>
          </Flex>
          <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
            {data ? (
              <>
                <chakra.h3
                  textAlign={'center'}
                  fontSize={'4xl'}
                  py={10}
                  fontWeight={'bold'}
                >
                  Statistics of the Site from {stat.from} to {stat.to}
                </chakra.h3>
                <SimpleGrid
                  columns={{ base: 1, md: 3 }}
                  spacing={{ base: 5, lg: 8 }}
                >
                  {/* <StatsCard
                title={'Users'}
                stat={'5,000'}
                icon={<BsPerson size={'3em'} />}
              /> */}
                  {/* <StatsCard
                title={'Servers'}
                stat={'1,000'}
                icon={<FiServer size={'3em'} />}
              /> */}
                  <StatsCard
                    title={'Income'}
                    stat={data?.stats?.totalAmountInUsd.toLocaleString(
                      'fr-FR',
                      {}
                    )}
                    icon={<FiDollarSign size={'3em'} />}
                  />

                  <StatsCard
                    title={'Income'}
                    stat={data?.stats?.totalAmountInEur.toLocaleString(
                      'fr-FR',
                      {}
                    )}
                    icon={'EUR'}
                  />

                  <StatsCard
                    title={'Income'}
                    stat={data?.stats?.totalAmount.toLocaleString('fr-FR', {})}
                    icon={'BIF'}
                  />

                  <StatsCard
                    title={'Orders'}
                    stat={data?.stats?.orders}
                    icon={<BsPerson size={'3em'} />}
                  />

                  <StatsCard
                    title={'Products'}
                    stat={data?.stats?.products}
                    icon={<FaBarcode size={'3em'} />}
                  />
                </SimpleGrid>
              </>
            ) : loading ? (
              <Loading />
            ) : (
              <></>
            )}
          </Box>
        </CardBody>
      </Card>
    </>
  );
}

export default Stats;
