import React, { useState, useRef } from 'react';
import { FiPrinter, FiPlusCircle, FiSearch } from 'react-icons/fi';
import ReactToPrint from 'react-to-print';
import {
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import Table2 from './Table';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ORDERS } from '../../config/graphql';
import Loading from '../Loading';
import Error from '../Error';

function Orders() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  let navigate = useNavigate();
  const ref = useRef();
  const { loading, error, data } = useQuery(GET_ORDERS);
  if (loading) return <Loading />;
  if (error) return <Error data={error} />;
  return (
    <>
      <HStack justifyContent={'space-between'}>
        <Heading size={'md'}>Orders</Heading>

        {/* <Button size={'sm'} onClick={onOpen} colorScheme={'blue'}>  */}
        {/* <Button
          size={'sm'}
          onClick={() => navigate('products/create')}
          color={'white'}
          bg={'brand.primary'}
          _hover={{ bg: 'brand.hoverPrimary' }}
        >
          <FiPlusCircle /> &nbsp; New
        </Button> */}
      </HStack>
      <br />

      <Card>
        <CardBody>
          <Flex spacing={4} direction={['column', 'row']}>
            <HStack>
              <Input
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
              />
              <Input
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
              />
            </HStack>

            <InputGroup ml={'2'} mt={{ base: '2', md: '0' }}>
              <InputLeftElement pointerEvents="none">
                <FiSearch color="gray.300" />
              </InputLeftElement>
              <Input
                maxW={{ base: 'full', md: '300' }}
                type="text"
                placeholder="ex: 001 or Name"
                onChange={event => {
                  setSearchTerm(event.target.value);
                }}
              />
            </InputGroup>

            {/* <ReactToPrint
              bodyClass="print-agreement"
              content={() => ref.current}
              trigger={() => (
                <Button
                  color={'white'}
                  bg={'brand.primary'}
                  _hover={{ bg: 'brand.hoverPrimary' }}
                  size={'sm'}
                  // px={5}
                  mt={{ base: '2', md: '0' }}
                >
                  <FiPrinter /> &nbsp; Print
                </Button>
              )}
            /> */}
          </Flex>
          <br />
          <div ref={ref}>
            <Table2
              data={data}
              startDate={startDate}
              endDate={endDate}
              search={searchTerm}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default Orders;
