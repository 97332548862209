import { gql } from '@apollo/client';

// PRODUCT
// QUERIES
export const GET_PRODUCTS = gql`
  query {
    products {
      id
      taxable
      vatRate
      productCode
      productType
      imageUrl
      name
      category
      description
      qty
      priceInEUR
      priceInUSD
      priceInBIF
      discount
      # currency
      options {
        title
        variations {
          name
          qty
          priceInEUR
          priceInUSD
          priceInBIF
        }
      }
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_PRODUCT = gql`
  query product($id: String!) {
    product(id: $id) {
      id
      productType
      taxable
      vatRate
      productCode
      imageUrl
      name
      category
      description
      qty
      minQty
      priceInEUR
      priceInUSD
      priceInBIF
      discount
      # currency
      options {
        title
        variations {
          name
          qty
          priceInEUR
          priceInUSD
          priceInBIF
        }
      }
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_MY_PRODUCT = gql`
  query {
    myProducts {
      id
      taxable
      vatRate
      productCode
      productType
      imageUrl
      name
      category
      description
      qty
      priceInEUR
      priceInUSD
      priceInBIF
      discount
      # currency
      options {
        title
        variations {
          name
          qty
          priceInEUR
          priceInUSD
          priceInBIF
        }
      }
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
// MUTATIONS
export const CREATE_PRODUCT = gql`
  mutation createProduct(
    $productType: String
    $imageUrl: [String]
    $name: String
    $category: String
    $description: String
    $qty: Int
    $minQty: Int
    $priceInEUR: Float
    $priceInUSD: Float
    $priceInBIF: Float
    $discount: Float
    # $currency: String
    $title: String
  ) {
    createProduct(
      productType: $productType
      imageUrl: $imageUrl
      name: $name
      category: $category
      description: $description
      qty: $qty
      minQty: $minQty
      priceInEUR: $priceInEUR
      priceInUSD: $priceInUSD
      priceInBIF: $priceInBIF
      discount: $discount
      # currency: $currency
      title: $title
    ) {
      id
      productCode
    }
  }
`;
export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: ID!
    $imageUrl: [String]
    $name: String
    $category: String
    $description: String
    $reason: String
    $details: String
    $qty: Int
    $minQty: Int
    $priceInEUR: Float
    $priceInUSD: Float
    $priceInBIF: Float
    $discount: Float
  ) {
    updateProduct(
      id: $id
      imageUrl: $imageUrl
      name: $name
      category: $category
      description: $description
      reason: $reason
      details: $details
      qty: $qty
      minQty: $minQty
      priceInEUR: $priceInEUR
      priceInUSD: $priceInUSD
      priceInBIF: $priceInBIF
      discount: $discount
    ) {
      id
      productCode
    }
  }
`;
export const CREATE_VARIATION = gql`
  mutation addVariation(
    $productId: ID!
    $name: String
    $qty: Int
    $priceInEUR: Float
    $priceInUSD: Float
    $priceInBIF: Float
  ) {
    addVariation(
      productId: $productId
      name: $name
      qty: $qty
      priceInEUR: $priceInEUR
      priceInUSD: $priceInUSD
      priceInBIF: $priceInBIF
    ) {
      id
      productCode
    }
  }
`;
export const DELETE_VARIATION = gql`
  mutation removeVariation($productId: ID!, $name: String) {
    removeVariation(productId: $productId, name: $name) {
      id
      productCode
    }
  }
`;
export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: String!) {
    deleteProduct(id: $id) {
      id
      productCode
    }
  }
`;
export const CHANGE_PRICES = gql`
  mutation Mutation($coefficient: Float) {
    changePrices(coefficient: $coefficient) {
      code
      status
      message
    }
  }
`;

// USER
// QUERIES
export const GET_ALL_USERS = gql`
  query {
    users {
      id
      imageUrl
      name
      phone
      email
      country
      city
      address
      role
      birthdate
      gender
      createdAt
      active
      lastLogin
    }
  }
`;

export const GET_USER = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      imageUrl
      name
      phone
      email
      country
      city
      address
      role
      birthdate
      gender
      createdAt
    }
  }
`;

export const GET_ME_USER = gql`
  query {
    meUser {
      id
      imageUrl
      name
      phone
      email
      country
      city
      address
      role
      birthdate
      gender
      createdAt
    }
  }
`;
// MUTATIONS
export const LOGIN = gql`
  mutation loginSeller($email: String, $password: String) {
    loginSeller(email: $email, password: $password) {
      userId
      imageUrl
      name
      role
      accessToken
      refreshToken
    }
  }
`;

export const SEND_RESET_LINK = gql`
  mutation sendResetLink($email: String!) {
    sendResetLink(email: $email)
  }
`;

export const PASSWORD_REST = gql`
  mutation newPassword($userId: ID!, $token: String!, $password: String) {
    newPassword(userId: $userId, token: $token, password: $password) {
      id
      name
      email
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation resetPassword($oldPassword: String, $newPassword: String) {
    resetPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`;
export const CREATE_USER = gql`
  mutation createUser(
    $name: String
    $email: String
    $phone: String
    $password: String
    $role: String
  ) {
    createUser(
      name: $name
      email: $email
      phone: $phone
      password: $password
      role: $role
    ) {
      userId
      imageUrl
      name
      role
      accessToken
      refreshToken
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String
    $email: String
    $imageUrl: String
    $phone: String
    $country: String
    $city: String
    $address: String
  ) {
    updateUser(
      id: $id
      name: $name
      email: $email
      imageUrl: $imageUrl
      phone: $phone
      country: $country
      city: $city
      address: $address
    ) {
      id
    }
  }
`;
export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      role
      active
    }
  }
`;

// ORDERS
// QUERIES
export const GET_ORDERS = gql`
  query {
    orders {
      id
      status
      orderId
      currency
      items {
        item_code
        name
        option {
          title
        }
        qty
        price
        amount
        vat
        total
      }
      amount
      vat
      total
      paymentMethod
      paidOn
      deliveredOn
      transactionId
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_ORDER = gql`
  query order($id: String!) {
    order(id: $id) {
      id
      status
      orderId
      currency
      items {
        item_code
        name
        option {
          title
          name
        }
        qty
        price
        amount
        vat
        total
      }
      amount
      vat
      total
      paymentMethod
      paidOn
      deliveredOn
      transactionId
      createdBy {
        id
        name
        city
        country
        address
      }
      updatedBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
// MUTATIONS
export const CHANGE_STATUS = gql`
  mutation changeStatus($id: String!, $status: String) {
    changeStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
// STATS
export const GET_STATISTICS = gql`
  mutation stats($to: String, $from: String) {
    stats(to: $to, from: $from) {
      orders
      totalAmount
      products
      totalAmountInEur
      totalAmountInUsd
    }
  }
`;
