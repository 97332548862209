import React, { useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
  InputGroup,
  InputLeftAddon,
  Icon,
  InputRightElement,
  Text,
  Link,
} from '@chakra-ui/react';
import { FaDotCircle } from 'react-icons/fa';
import { BsPerson } from 'react-icons/bs';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { isValidEmail } from '../config/validations';
import Error from '../components/Error';
import { LOGIN } from '../config/graphql';

function Login() {
  const [show, setShow] = React.useState(false);
  const handlePasswordClick = () => setShow(!show);
  const [user, setUser] = React.useState({
    email: '',
    password: '',
  });
  let navigate = useNavigate();
  let location = useLocation();

  const [loginSeller, { error, loading }] = useMutation(LOGIN);

  const handleOnChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleOnSubmit = event => {
    if (!isValidEmail(user.email)) {
      alert('Invalid email!');
    } else {
      loginSeller({ variables: { ...user } })
        .then(data => {
          const authData = data.data.loginSeller;
          localStorage.setItem('user', JSON.stringify(authData));
          // if (location.state?.from) {
          //   navigate(location.state.from);
          // } else {
          navigate('/dashboard');
          // }
        })
        .catch(e => console.log(e));
    }
    event.preventDefault();
  };

  //    if (data) {
  //      const authData = data.login;
  //
  //      navigate('/');
  //    }
  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Heading fontSize={'2xl'}>Sign in to your account</Heading>
          <form>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftAddon children={<Icon as={BsPerson} />} />
                <Input name="email" type="email" onChange={handleOnChange} />
              </InputGroup>
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <InputLeftAddon children={<Icon as={FaDotCircle} />} />
                <Input
                  name="password"
                  type={show ? 'text' : 'password'}
                  onChange={handleOnChange}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handlePasswordClick}>
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <br />
            <Stack spacing={6}>
              {loading ? (
                <Button
                  isLoading
                  loadingText="Submitting"
                  bg={'brand.primary'}
                  color={'white'}
                  variant="outline"
                  _hover={{
                    bg: 'brand.hoverPrimary',
                  }}
                  onClick={() => handleOnSubmit}
                >
                  Submitting
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="solid"
                  onClick={handleOnSubmit}
                  bg={'blue.400'}
                  color={'white'}
                  w="full"
                  _hover={{
                    bg: 'brand.hoverPrimary',
                  }}
                >
                  Sign in
                </Button>
              )}
              {error ? <Error data={error} /> : <></>}
              <Stack pt={6}>
                {/* <Text align={'center'}>
                  Not a user?{' '}
                  <Link
                    onClick={() => navigate('/signup')}
                    color={'brand.primary'}
                  >
                    Signup
                  </Link>
                </Text> */}
                <Text align={'center'}>
                  <Link
                    onClick={() => navigate('/forgot-password')}
                    color={'brand.primary'}
                  >
                    Forgot Password?
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </form>
          {/* {error ? (
            <>
              <Error data={error} />
            </>
          ) : (
            <></>
          )} */}
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
          }
        />
      </Flex>
    </Stack>
  );
}

export default Login;
