import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { CHANGE_STATUS, GET_ORDERS } from '../../config/graphql';
import Error from '../Error';

function ChangeStatusModal(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setstatus] = useState('');
  const [changeStatus, { loading, error, data }] = useMutation(CHANGE_STATUS, {
    refetchQueries: { query: GET_ORDERS },
  });
  function CloseModal() {
    props.setOpen(false);
  }
  const handleOnChange = e => {
    setstatus({ ...status, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = e => {
    status.id = props.data.title;

    changeStatus({ variables: { ...status } })
      .then(data => {
        CloseModal();
      })
      .catch(e => console.log(e));
    e.preventDefault();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={() => props.setOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Status:</FormLabel>
            <Select
              name={'status'}
              defaultValue={props.data.status}
              onChange={handleOnChange}
            >
              <option value="processing">processing</option>
              <option value="completed">completed</option>
              <option value="paid">paid</option>
              <option value="pending payment">pending payment</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => props.setOpen(false)}>
            Close
          </Button>
          <Button
            isLoading={loading}
            color={'white'}
            bg={'brand.primary'}
            _hover={{ bg: 'brand.hoverPrimary' }}
            onClick={handleOnSubmit}
            loadingText={'Changing'}
          >
            Change
          </Button>
        </ModalFooter>
        {!error ? <></> : <Error data={error} />}
      </ModalContent>
    </Modal>
  );
}

export default ChangeStatusModal;
