import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import {
  DELETE_PRODUCT,
  GET_PRODUCTS,
  DELETE_USER,
  GET_ALL_USERS,
} from '../config/graphql';
import Loading from './Loading';
import Error from './Error';

function DeleteWarning(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [product, setProduct] = useState();

  function CloseModal() {
    props.setOpen(false);
  }
  const [
    deleteProduct,
    { loading: loadingProduct, error: errorProduct, data: dataProduct },
  ] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [{ query: GET_PRODUCTS }],
  });

  const [
    deleteUser,
    { loading: loadingUser, error: errorUser, data: dataUser },
  ] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_ALL_USERS }],
  });

  function deleteHandlerProduct(id) {
    deleteProduct({ variables: { ...{ id } } })
      .then(dataProduct => {
        CloseModal();
      })
      .catch(e => console.log(e));
  }

  function deleteHandlerUser(id) {
    deleteUser({ variables: { ...{ id } } })
      .then(dataUser => {
        CloseModal();
      })
      .catch(e => console.log(e));
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={() => props.setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {props.data.operation === 'user' ? (
              <>Delete User</>
            ) : (
              <>Delete Product</>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {props.data.status === true ? (
              <Text>Do you want to Ban User {props.data.name}</Text>
            ) : props.data.status === null ? (
              <Text>Delete Product {props.data.name}</Text>
            ) : (
              <Text>Do you want to Activate User {props.data.name}</Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={() => props.setOpen(false)}>
              Close
            </Button>
            {props.data.status === null ? (
              <Button
                isLoading={loadingProduct}
                color={'white'}
                bg={'brand.primary'}
                _hover={{ bg: 'brand.hoverPrimary' }}
                mr={3}
                onClick={() => {
                  deleteHandlerProduct(props.data.title);
                }}
                loadingText="Deleting"
              >
                Delete
              </Button>
            ) : (
              <Button
                isLoading={loadingUser}
                color={'white'}
                bg={'brand.primary'}
                _hover={{ bg: 'brand.hoverPrimary' }}
                mr={3}
                onClick={() => {
                  deleteHandlerUser(props.data.title);
                }}
                loadingText="Deleting"
              >
                {props.data.status === false ? <>Activate</> : <>Ban</>}
              </Button>
            )}
          </ModalFooter>
          {errorProduct ? (
            <Error data={errorProduct} />
          ) : errorUser ? (
            <Error data={errorUser} />
          ) : (
            <></>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteWarning;
