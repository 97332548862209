import { jwtDecode } from 'jwt-decode';

const api_url = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const handleRefresh = async (accessToken, refreshToken) => {
  const response = await fetch(`${api_url}/api`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
                  mutation refreshTokens($accessToken: String, $refreshToken: String) {
                    refreshTokens(accessToken: $accessToken, refreshToken: $refreshToken) {
                        name
                        userId
                        role
                        accessToken
                        refreshToken
                    }
                  }
                `,
      variables: {
        accessToken: accessToken,
        refreshToken: refreshToken,
      },
    }),
  });
  return response.json();
};

const isValidToken = token => {
  if (!token) return false;

  // Otherwise, we check if the token is expired
  const decodedToken = jwtDecode(token);
  const expirationTimeInSeconds = decodedToken.exp * 1000;
  const now = new Date();
  const isValid = expirationTimeInSeconds >= now.getTime();

  // Return true if the token is still valid, otherwise false and trigger a token refresh
  return isValid;
};

//LOG OUT

export { isValidToken };
export default handleRefresh;
