import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  Card,
  CardBody,
  Thead,
  Tr,
  Td,
  Tbody,
  Button,
  Input,
  Badge,
  Heading,
  CardHeader,
  HStack,
  useDisclosure,
  ButtonGroup,
} from '@chakra-ui/react';
import { User } from '../../data/Data';
import { useNavigate } from 'react-router-dom';
import DeleteWarning from '../DeleteWarning';
import { FiPlusCircle } from 'react-icons/fi';
import CreateUsers from './CreateUsers';
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS } from '../../config/graphql';
import Loading from '../Loading';
import Error from '../Error';

function ListUsers() {
  const [searchTerm, setSearchTerm] = useState('');
  let navigate = useNavigate();
  const [isShow, setShow] = useState(false);
  const [isDeleteId, setDeleteId] = useState(false);
  const [name, setName] = useState('');
  const [status, setStatus] = useState();
  const handleShow = msg => {
    setShow(!msg);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading, error, data } = useQuery(GET_ALL_USERS);
  let user = JSON.parse(localStorage.getItem('user'));
  if (loading) return <Loading />;
  if (error) return <Error data={error} />;

  return (
    <>
      <DeleteWarning
        setOpen={setShow}
        isOpen={isShow}
        data={{
          operation: 'user',
          title: isDeleteId,
          name: name,
          status: status,
        }}
      />
      <CreateUsers onClose={onClose} isOpen={isOpen} />

      <HStack justifyContent={'space-between'}>
        <Heading size={'md'}>Users</Heading>
        {user?.role === 'admin' ? (
          <Button
            size={'sm'}
            onClick={onOpen}
            bg={'brand.primary'}
            color={'white'}
            _hover={{
              bg: 'brand.hoverPrimary',
            }}
          >
            <FiPlusCircle /> &nbsp; New
          </Button>
        ) : (
          <></>
        )}
      </HStack>
      <br />

      <Card>
        <CardHeader>
          {/* SEARCH INPUT */}
          <Input
            maxW={'sm'}
            onChange={event => {
              setSearchTerm(event.target.value);
            }}
            placeholder="name"
          />
        </CardHeader>
        <CardBody>
          {/* LIST OF PRODUCT NAMES */}
          {User ? (
            <TableContainer overflowX="auto">
              <Table variant="striped" size="sm" colorScheme={'teal'}>
                <Thead>
                  <Tr>
                    <Td>Role</Td>
                    <Td> Name</Td>

                    {/* <Td>Username</Td> */}
                    <Td>Email</Td>
                    <Td>Phone</Td>
                    <Td>Status</Td>
                    <Td>Actions</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.users
                    .filter(val => {
                      let username;
                      if (searchTerm === '') {
                        username = val;
                      } else if (
                        val.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        username = val;
                      }
                      return username;
                    })
                    .map((val, index) => (
                      <Tr key={index}>
                        <Td>{val.role}</Td>
                        <Td>{val.name}</Td>
                        {/* <Td>{val.username}</Td> */}
                        <Td>{val.email}</Td>
                        <Td>{val.phone}</Td>
                        <Td>
                          {val.active === true ? (
                            <Badge colorScheme="green" variant="solid">
                              active
                            </Badge>
                          ) : (
                            <Badge colorScheme="red" variant="solid">
                              deactive
                            </Badge>
                          )}
                        </Td>
                        <Td>
                          <ButtonGroup gap={2}>
                            <Button
                              onClick={() => {
                                navigate(`/dashboard/users/${val.id}`);
                              }}
                              bg={'brand.primary'}
                              color={'white'}
                              _hover={{
                                bg: 'brand.hoverPrimary',
                              }}
                            >
                              Details
                            </Button>
                            {val.active === true ? (
                              <Button
                                onClick={() => {
                                  setDeleteId(val.id);
                                  setName(val.name);
                                  setStatus(val.active);
                                  handleShow();
                                }}
                                bg={'red.400'}
                                color={'white'}
                                _hover={{
                                  bg: 'red.600',
                                }}
                              >
                                Ban
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  setDeleteId(val.id);
                                  setName(val.name);
                                  setStatus(val.active);
                                  handleShow();
                                }}
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                  bg: 'blue.600',
                                }}
                              >
                                Activate
                              </Button>
                            )}
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default ListUsers;
