export const User = [
  {
    id: 'ebfc9003kkkkkmnjkf',

    firstName: 'Kabura',
    lastName: 'jean',

    phone: '61234567',
    email: 'K@gmail.com',

    role: 'user', // (user,seller,editor,admin,superAdmin)

    password: '1234',

    isTaxPayer: false,

    birthdate: '23/12/2024',

    gender: 'male',

    country: 'Burundi', // Burundi, Rwanda, DRC, Tanzania, (list)

    city: 'Bujumbura', // (list)

    address: 'Muha', // (libre)
    bussinesName: 'magasin akabirya',
    status: 'deactive',
    createdAt: '23/12/2023',
  },
  {
    id: 'ebfc9003kkkkkmnjkf',

    firstName: 'Kabura',
    lastName: 'jean',

    phone: '61234567',
    email: 'K@gmail.com',

    role: 'user', // (user,seller,editor,admin,superAdmin)

    password: '1234',

    isTaxPayer: false,

    birthdate: '23/12/2024',

    gender: 'male',

    country: 'Burundi', // Burundi, Rwanda, DRC, Tanzania, (list)

    city: 'Bujumbura', // (list)

    address: 'Muha', // (libre)
    bussinesName: 'magasin akabirya',
    status: 'active',
  },
];

export const Product = [
  {
    id: 'ebd78883kiol',

    status: 'published', // (deleted, published, pending review)

    taxable: false,

    vatRate: 0,

    name: 'chocolate',

    category: 'eatable',

    qty: 100,

    description: 'Dark chocolate',

    price: 500,

    discount: 0,

    currency: 'BIF',

    imageUrl: ['https://bit.ly/dan-abramov'],

    createdBy: { id: 'ebcd8949494kkkf', name: 'lolillo' },
    createdAt: '23/05/2024',
    options: {
      title: 'Mars', // String e.g: Storage, Size, Type,…

      variations: [
        {
          name: 'mars kindly',
          // String e.g: 16GB,

          price: 501,
        },
      ],
    },
  },
  {
    id: 'ebd78883kiol',

    status: 'published', // (deleted, published, pending review)

    taxable: false,

    vatRate: 0,

    name: 'chocolate',

    category: ['eatable', 'sweet', 'gift', 'valentine'],

    qty: 100,

    description: 'Dark chocolate',

    price: 500,

    discount: 0,

    currency: 'BIF',

    imageUrl: ['https://bit.ly/dan-abramov'],

    createdBy: { id: 'ebcd8949494kkkf', name: 'lolillo' },
    createdAt: '23/05/2024',
  },
  {
    id: 'ebd78883kiol',

    status: 'published', // (deleted, published, pending review)

    taxable: false,

    vatRate: 0,

    name: 'chocolate',

    category: ['eatable', 'sweet', 'gift', 'valentine'],

    qty: 100,

    description: 'Dark chocolate',

    price: 500,

    discount: 0,

    currency: 'BIF',

    imageUrl: [
      'https://images.unsplash.com/photo-1596516109370-29001ec8ec36?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyODE1MDl8MHwxfGFsbHx8fHx8fHx8fDE2Mzg5MzY2MzE&ixlib=rb-1.2.1&q=80&w=1080',
      'https://images.unsplash.com/photo-1596516109370-29001ec8ec36?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyODE1MDl8MHwxfGFsbHx8fHx8fHx8fDE2Mzg5MzY2MzE&ixlib=rb-1.2.1&q=80&w=1080',
      'https://images.unsplash.com/photo-1596516109370-29001ec8ec36?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyODE1MDl8MHwxfGFsbHx8fHx8fHx8fDE2Mzg5MzY2MzE&ixlib=rb-1.2.1&q=80&w=1080',
      'https://images.unsplash.com/photo-1596516109370-29001ec8ec36?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyODE1MDl8MHwxfGFsbHx8fHx8fHx8fDE2Mzg5MzY2MzE&ixlib=rb-1.2.1&q=80&w=1080',
    ],

    createdBy: { id: 'ebcd8949494kkkf', name: 'lolillo' },
    createdAt: '23/05/2024',
    options: {
      title: 'storage',
      variation: [
        { name: '16gb', price: 20, qty: 30 },
        { name: '24gb', price: 25, qty: 15 },
        { name: '10pouce', price: 50, qty: 30 },
        { name: '24pouce', price: 80, qty: 25 },
      ],
    },
  },
];

export const Order = [
  {
    id: 'ebc89933koll',

    status: 'processing', // (deleted, pending payment, paid, processing, completed)

    orderId: 'od1256', // (Auto increment)

    currency: 'BIF',

    items: [
      {
        id: 'ebckki98404',
        name: 'chemise',
        qty: 10,
        option: 'blue',
        price: 500,

        vat: 0,

        total: 5000,
      },
    ],

    amount: 5000,

    vat: 0,

    total: 5000,

    paymentMethod: 'cash',

    paidOn: '24/06/2024',

    deliveredOn: '24/06/2024',

    transactionId: 'p24ol90',

    createdBy: {
      id: 'tyh9067',
      name: 'nestor ',
      phone: '7986095',
      city: 'Bujumbura',
    },

    createdAt: '23/06/2024',
    commission: 0,
  },
  {
    id: 'ebc89933koll',

    status: 'processing', // (deleted, pending payment, paid, processing, completed)

    orderId: 'od1256', // (Auto increment)

    currency: 'BIF',

    items: [
      {
        id: 'ebckki98404',
        name: 'chemise',

        qty: 10,

        price: 500,

        vat: 0,

        total: 5000,
      },
    ],

    amount: 5000,

    vat: 0,

    total: 5000,

    paymentMethod: 'mobile',

    paidOn: '24/06/2024',

    deliveredOn: '24/06/2024',

    transactionId: 'p24ol90',

    createdBy: { id: 'tyh9067', name: 'nestor' },

    createdAt: '23/06/2024',
    commission: 0,
  },
];
