import React, { ReactNode } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
} from '@chakra-ui/react';
import {
  FiCodesandbox,
  FiList,
  FiUser,
  FiMenu,
  FiChevronDown,
  FiBell,
} from 'react-icons/fi';

import { IoStatsChartSharp } from 'react-icons/io5';
import { ReactText } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import logo2 from '../assets/logo.png';
import { useApolloClient } from '@apollo/client';

interface LinkItemProps {
  name: string;
  icon: IconType;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Products', icon: FiCodesandbox, link: '/dashboard' },
  { name: 'Orders', icon: FiList, link: '/dashboard/orders' },
  { name: 'Users', icon: FiUser, link: '/dashboard/users' },
  { name: 'Stats', icon: IoStatsChartSharp, link: '/dashboard/stats' },
];

let user = JSON.parse(localStorage.getItem('user'));

function Dashboard({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        bg={'brand.primary'}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, setCurrentLink, ...rest }: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box
      transition="3s ease"
      // bg={useColorModeValue('white', 'gray.900')}
      bg={'brand.primary'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image
          cursor={'pointer'}
          onClick={() => navigate('/dashboard')}
          width={100}
          src={logo}
        />
        {/* <Text
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
          color={'white'}
        >
          Logo
        </Text> */}
        <CloseButton
          color={'white'}
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
        />
      </Flex>
      {LinkItems.map((link, index) => {
        const isActive = location.pathname === link.link;
        const isLastItem = index === LinkItems.length - 1; // Check if it's the last item

        let shouldShowLastItem = user.role === 'admin';

        // Conditionally render the NavItem
        if (isLastItem && !shouldShowLastItem) {
          // If it's the last item and the condition is not met, don't render anything
          return null;
        }
        return (
          <NavItem
            key={link.name}
            icon={link.icon}
            onClose={onClose}
            bg={isActive ? 'white' : 'brand.primary'}
            color={isActive ? 'black' : 'white'}
            linkName={link.link}
          >
            {link.name}
          </NavItem>
        );
      })}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
}
const NavItem = ({
  icon,
  linkName,
  onClose,
  children,
  ...rest
}: NavItemProps) => {
  const navigate = useNavigate();
  return (
    <Link
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={() => {
        navigate(linkName);
        onClose();
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const handleLogout = () => {
    window.localStorage.clear();
    localStorage.removeItem('user');
    client.clearStore();
    navigate('/');
  };

  return (
    <>
      <Flex
        bg={'white'}
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        {...rest}
      >
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Image
          cursor={'pointer'}
          onClick={() => navigate('/dashboard')}
          display={{ base: 'flex', md: 'none' }}
          width={100}
          src={logo2}
        />

        <HStack spacing={{ base: '0', md: '6' }}>
          <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiBell />}
          />
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}
              >
                <HStack>
                  <Avatar src={user?.imageUrl} size={'sm'} name={user?.name} />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">
                      {user?.firstName} {user?.name}
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                <MenuItem onClick={() => navigate('/dashboard/account')}>
                  My Account
                </MenuItem>
                <MenuItem
                  onClick={() => navigate('/dashboard/change-password')}
                >
                  Change password
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => handleLogout()}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
      {/* <div
        style={{
          postion: 'absolute',
          bottom: 0,
        }}
      >
        <Tabs
          isFitted
          variant="enclosed"
          align="bottom"
          display={{ md: 'none' }}
        >
          <TabList mb="1em">
            <Tab>One</Tab>
            <Tab>Two</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <p>one!</p>
            </TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div> */}
    </>
  );
};
export default Dashboard;
