import React from 'react';
import Products from '../components/products/Products';
import MyProducts from '../components/products/MyProducts';

function ProductsDivide() {
  let localUser = JSON.parse(localStorage.getItem('user'));

  return <>{localUser.role === 'admin' ? <Products /> : <MyProducts />}</>;
}

export default ProductsDivide;
