import React from 'react';
import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';

export default function Error(props) {
  const error = props.data;
  const back = props.back;
  return (
    <>
      {back ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{error?.message}!</AlertTitle>
        </Alert>
      ) : (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{error?.message}!</AlertTitle>
        </Alert>
      )}
    </>
  );
}
