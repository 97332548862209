import React, { useState } from 'react';
import {
  CloseButton,
  Image,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

export default function MultipleUploading({
  product,
  setProduct,
  loadingFile,
  uploadFileHandler,
  fileError,
}) {
  const arrayUrls = product.imageUrl;

  const [file, setFile] = useState(null);

  const handleFileInputChange = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = event => {
      setFile(event.target.result);
    };
    reader?.readAsDataURL(file);
  };

  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://46.101.13.175:2222';

  const removeImagePath = pathToRemove => {
    const updatedImageUrls = product.imageUrl.filter(
      path => path !== pathToRemove
    );
    setProduct({ ...product, imageUrl: updatedImageUrls });
  };

  return (
    <div>
      <Wrap justify="center">
        {arrayUrls?.map((url, index) => (
          <WrapItem key={index}>
            <Image
              borderRadius={3}
              src={imageUrl + url}
              width={100}
              height={100}
            />
            <CloseButton
              onClick={() => removeImagePath(url)}
              size={'4xl'}
              width={100}
              height={100}
              ml={-100}
            />
          </WrapItem>
        ))}
      </Wrap>
      <br />
      <FormControl>
        <FormLabel>Upload Images</FormLabel>
        <input
          isDisable={loadingFile}
          type="file"
          name="imageUrl"
          onChange={event => {
            handleFileInputChange(event);
            uploadFileHandler(event);
          }}
        />
      </FormControl>
      {fileError ? <>{fileError}</> : <></>}
    </div>
  );
}
