import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  CardBody,
  Card,
  Container,
  useColorModeValue,
  SimpleGrid,
  CardHeader,
  HStack,
  Box,
  Select,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  InputRightAddon,
  InputGroup,
  TableContainer,
  Text,
  Badge,
  VStack,
} from '@chakra-ui/react';
import { FiPlusCircle } from 'react-icons/fi';
import OptionsModal from './OptionsModal';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_VARIATION,
} from '../../config/graphql';
import Loading from '../Loading';
import Error from '../Error';
import MultipleUploading from '../MultipleUploading';
import axios from 'axios';

function EditProduct() {
  const [isShow, setShow] = useState(false);
  const { id } = useParams();
  let url = process.env.REACT_APP_IMAGE_URL || 'http://46.101.13.175:2222';
  const handleShow = msg => {
    setShow(!msg);
  };

  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { id },
  });
  const [product, setProduct] = useState('');
  useEffect(() => {
    if (data) setProduct({ imageUrl: data.product?.imageUrl });
  }, [data]);
  const [
    updateProduct,
    { loading: loadingUpdate, error: errorUpdate, data: dataUpdate },
  ] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: [{ query: GET_PRODUCT, variables: { id } }],
  });

  const [deleteVariaton, { loading: loadingD, error: errorD, data: dataD }] =
    useMutation(DELETE_VARIATION, {
      refetchQueries: [{ query: GET_PRODUCT, variables: { id } }],
    });

  const handleOnChange = e => {
    setProduct({ ...product, [e.target.name]: e.target.value });
    console.log(product);
  };
  const handleOnSubmit = e => {
    product.id = id;
    if (product.qty) product.qty = parseInt(product.qty);
    if (product.minQty) product.minQty = parseInt(product.minQty);
    if (product.priceInEUR) product.priceInEUR = parseFloat(product.priceInEUR);
    if (product.priceInUSD) product.priceInUSD = parseFloat(product.priceInUSD);
    if (product.priceInBIF) product.priceInBIF = parseFloat(product.priceInBIF);
    if (product.discount) product.discount = parseFloat(product.discount);
    updateProduct({ variables: { ...product } }).catch(e => console.log(e));
    e.preventDefault();
  };

  function deleteHandlerVariation(id, name) {
    deleteVariaton({ variables: { ...{ productId: id, name: name } } });
    // .then(dataD => {

    // });
  }

  // ============UPLOAD FILES=====================//

  const [loadingFile, setLoadingFile] = useState(false);
  const [fileError, setFileError] = useState();

  const uploadFileHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      alert('No File added!');
    } else {
      try {
        setLoadingFile(true);
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post(`${url}/upload`, formData);

        if (response.status === 200) {
          const { path } = response.data;
          // console.log('File uploaded successfully:', path);
          setFileError(null);
          setProduct({ ...product, imageUrl: [...product.imageUrl, path] });
        } else {
          console.error('File upload failed');
          // Handle error, e.g., show an error message or update UI accordingly
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setFileError(error);
      } finally {
        setLoadingFile(false);
      }
    }
  };
  // ============END UPLOAD FILES=================//

  const bg1 = useColorModeValue('brand.primary', 'gray.50');
  const color1 = useColorModeValue('white', 'gray.900');

  if (loading) return <Loading />;
  if (error) return <Error data={error} />;
  // if (data) {
  //   // setProduct({ imageUrl: data.product?.imageUrl });
  // }
  // if (dataM) {
  //   window.location.reload();
  // }
  return (
    <>
      <OptionsModal
        setOpen={setShow}
        isOpen={isShow}
        data={{
          id: id,
        }}
      />
      <Container maxW={'7xl'}>
        <Heading size={'md'}>Edit Product</Heading>
        <br />
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 18, md: 18 }}
        >
          <Card>
            <CardHeader>
              <Heading fontSize={'20'}>Details</Heading>
            </CardHeader>
            <CardBody>
              <Stack spacing={4}>
                <Stack spacing={6} direction={{ base: 'column', md: 'row' }}>
                  <Box>
                    <FormControl id="name">
                      <FormLabel>Name:</FormLabel>
                      <Input
                        name="name"
                        type="text"
                        onChange={handleOnChange}
                        defaultValue={data.product?.name}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="category">
                      <FormLabel>Category:</FormLabel>
                      <Select
                        defaultValue={data.product?.category}
                        name="category"
                        onChange={handleOnChange}
                      >
                        <option value="Electronics">Electronics</option>
                        <option value="Computers">Computers</option>
                        <option value="CCTV Surveillance Camera">
                          CCTV (Surveillance Camera)
                        </option>
                        <option value="Fiber Optic Items">
                          Fiber Optic Items
                        </option>
                        <option value="Fire Detection Systems">
                          Fire Detection Systems
                        </option>
                        <option value="Printers">Printers</option>
                        <option value="Accessories">Accessories</option>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
                <FormControl id="description">
                  <FormLabel>Description:</FormLabel>
                  <Textarea
                    name="description"
                    variant="filled"
                    defaultValue={data.product?.description}
                    _placeholder={{
                      opacity: 1,
                      color: 'inherit',
                      fontSize: 'xl',
                    }}
                    onChange={handleOnChange}
                  />
                </FormControl>
                {data?.product?.productType === 'simple' ? (
                  <>
                    {' '}
                    <Stack
                      spacing={6}
                      direction={{ base: 'column', md: 'row' }}
                    >
                      <Box>
                        <FormControl id="qty">
                          <FormLabel>Quantity:</FormLabel>
                          <Input
                            name="qty"
                            type="text"
                            defaultValue={data.product?.qty}
                            onChange={handleOnChange}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl id="discount">
                          <FormLabel>Discount:</FormLabel>
                          <InputGroup>
                            <Input
                              name="discount"
                              type="text"
                              defaultValue={data.product?.discount * 100}
                              onChange={handleOnChange}
                            />
                            <InputRightAddon>%</InputRightAddon>
                          </InputGroup>
                        </FormControl>
                      </Box>
                    </Stack>
                    <VStack align="stretch">
                      <Box>
                        <FormControl id="priceInBIF">
                          <FormLabel>Price In BIF:</FormLabel>
                          <InputGroup>
                            <Input
                              name="priceInBIF"
                              type="text"
                              defaultValue={data.product?.priceInBIF}
                              onChange={handleOnChange}
                            />
                            <InputRightAddon>BIF</InputRightAddon>
                          </InputGroup>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl id="priceInUSD">
                          <FormLabel>Price In USD:</FormLabel>
                          <InputGroup>
                            <Input
                              name="priceInUSD"
                              type="text"
                              defaultValue={data.product?.priceInUSD}
                              onChange={handleOnChange}
                            />
                            <InputRightAddon>USD</InputRightAddon>
                          </InputGroup>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl id="priceInEUR">
                          <FormLabel>Price In EUR:</FormLabel>
                          <InputGroup>
                            <Input
                              name="priceInEUR"
                              type="text"
                              defaultValue={data.product?.priceInEUR}
                              onChange={handleOnChange}
                            />
                            <InputRightAddon>EUR</InputRightAddon>
                          </InputGroup>
                        </FormControl>
                      </Box>
                    </VStack>
                  </>
                ) : (
                  <></>
                )}

                <Stack spacing={6} direction={{ base: 'column', md: 'row' }}>
                  <Box>
                    <FormControl id="minQty">
                      <FormLabel>Stock Alert:</FormLabel>
                      <Input
                        name="minQty"
                        type="text"
                        placeholder="ex: 20"
                        defaultValue={data?.product?.minQty}
                        onChange={handleOnChange}
                      />
                    </FormControl>
                  </Box>
                </Stack>

                <br />
                {data.product.options.title != null ? (
                  <>
                    <HStack justifyContent={'space-between'}>
                      <Heading fontSize={'20'}>Options:</Heading>

                      <Button
                        size={'sm'}
                        onClick={() => handleShow()}
                        color={'white'}
                        bg={'brand.primary'}
                        _hover={{ bg: 'brand.hoverPrimary' }}
                      >
                        <FiPlusCircle /> &nbsp; Add
                      </Button>
                    </HStack>
                    <Text mt={-2}>
                      <Badge variant="solid" colorScheme={'blue'} rounded>
                        {data.product.options?.title}
                      </Badge>
                    </Text>
                    <br />

                    <TableContainer>
                      <Table size={'sm'}>
                        <Thead>
                          <Tr>
                            <Th>Name</Th>
                            <Th>BIF</Th>
                            <Th>USD</Th>
                            <Th>EUR</Th>
                            <Th>Qty</Th>
                            <Th>Actions</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {data.product.options.variations?.map(val => (
                            <Tr key={val.id}>
                              <Td>{val.name}</Td>
                              <Td>
                                {val.priceInBIF.toLocaleString('fr-FR', {})}
                              </Td>
                              <Td>
                                {val.priceInUSD.toLocaleString('fr-FR', {})}
                              </Td>
                              <Td>
                                {val.priceInEUR.toLocaleString('fr-FR', {})}
                              </Td>
                              <Td> {val.qty} </Td>
                              <Td>
                                <Button
                                  colorScheme={'red'}
                                  onClick={() => {
                                    deleteHandlerVariation(id, val.name);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            </CardBody>
          </Card>
          {/* <Container maxW={'4xl'}> */}
          <Stack>
            <Card>
              <CardHeader>
                <Heading fontSize={'20'}>Images</Heading>
              </CardHeader>
              <CardBody>
                <Stack spacing={4}>
                  <MultipleUploading
                    fileError={fileError}
                    loadingFile={loadingFile}
                    uploadFileHandler={uploadFileHandler}
                    setProduct={setProduct}
                    product={product}
                  />
                </Stack>
              </CardBody>
            </Card>

            {/* REASON TO CHANGE THE QUANTITY */}
            {product?.qty &&
            product?.qty.toString() !== data?.product?.qty?.toString() ? (
              <Card>
                <CardBody>
                  <Heading fontSize={'20'}>
                    Reason To Change The Quantity
                  </Heading>
                  <br />
                  <FormControl>
                    <FormLabel>Reason</FormLabel>
                    <Select
                      name="reason"
                      placeholder="choose reason"
                      onChange={handleOnChange}
                    >
                      <option value="sale offsite">Sale Offsite</option>
                      <option value="purchase">Purchase</option>
                      <option value="loss">Loss/Obsolescence</option>
                      <option value="error">Error</option>
                    </Select>
                  </FormControl>
                  <br />
                  {product?.reason === 'sale offsite' ? (
                    <FormControl>
                      <FormLabel>Details of the sale offsite</FormLabel>
                      <Textarea name="details" onChange={handleOnChange} />
                    </FormControl>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            ) : (
              <></>
            )}

            {loadingUpdate ? (
              <Button
                isLoading
                rounded={'10'}
                w={'full'}
                mt={3}
                size={'lg'}
                py={'7'}
                bg={bg1}
                color={color1}
                textTransform={'uppercase'}
                _hover={{
                  transform: 'translateY(2px)',
                  boxShadow: 'lg',
                  bg: 'brand.hoverPrimary',
                }}
              >
                Updating
              </Button>
            ) : (
              <Button
                rounded={'10'}
                w={'full'}
                mt={3}
                size={'lg'}
                py={'7'}
                bg={bg1}
                color={color1}
                textTransform={'uppercase'}
                _hover={{
                  transform: 'translateY(2px)',
                  boxShadow: 'lg',
                  bg: 'brand.hoverPrimary',
                }}
                onClick={handleOnSubmit}
              >
                Update
              </Button>
            )}
            {errorUpdate ? <Error data={errorUpdate} /> : <></>}
          </Stack>

          {/* </Container> */}
        </SimpleGrid>
      </Container>
    </>
  );
}

export default EditProduct;
