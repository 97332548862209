import Moment from 'react-moment';

function Timer2(date) {
  var myDate = date / 1000;
  var unixTimestamp = myDate;
  return (
    <>
      <Moment format="DD-MM-YYYY" unix>
        {unixTimestamp}
      </Moment>
    </>
  );
}

const findMin = (data, currency) => {
  if (data.length > 0) {
    if (currency === 'BIF') {
      let result = data[0].priceInBIF;
      data.map(val => {
        if (val.priceInBIF < result) {
          result = val.priceInBIF;
        }
      });
      return result;
    } else if (currency === 'USD') {
      let result = data[0].priceInUSD;
      data.map(val => {
        if (val.priceInUSD < result) {
          result = val.priceInUSD;
        }
      });
      return result;
    } else {
      let result = data[0].priceInEUR;
      data.map(val => {
        if (val.priceInEUR < result) {
          result = val.priceInEUR;
        }
      });
      return result;
    }
  } else {
    return 0;
  }
};

const findMax = (data, currency) => {
  if (currency === 'BIF') {
    let result = data[0].priceInBIF;
    data.map(val => {
      if (val.priceInBIF > result) {
        result = val.priceInBIF;
      }
    });
    return result;
  } else if (currency === 'USD') {
    let result = data[0].priceInUSD;
    data.map(val => {
      if (val.priceInUSD > result) {
        result = val.priceInUSD;
      }
    });
    return result;
  } else {
    let result = data[0].priceInEUR;
    data.map(val => {
      if (val.priceInEUR > result) {
        result = val.priceInEUR;
      }
    });
    return result;
  }
};

export { Timer2, findMax, findMin };
