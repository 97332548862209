import React, { useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  RadioGroup,
  Radio,
  CardBody,
  Card,
  Container,
  HStack,
  Select,
  InputGroup,
  InputRightAddon,
  VStack,
} from '@chakra-ui/react';
import MultipleUploading from '../MultipleUploading';
import axios from 'axios';
import {
  CREATE_PRODUCT,
  GET_PRODUCTS,
  GET_MY_PRODUCT,
} from '../../config/graphql';
import { useMutation } from '@apollo/client';
import Error from '../Error';
import { useNavigate } from 'react-router-dom';

function CreateProducts() {
  const [product, setProduct] = useState({
    imageUrl: [],
  });
  const [value1, setValue1] = useState('simple');
  let navigate = useNavigate();
  const handleOnChange = e => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };
  // const handleOnChangeRadio = (e, value) => {
  //   // setProduct({ ...product, [e.target.name]: e.target.value });
  //   console.log(value);
  // };
  // let handleInputChange = e => {
  //   let inputValue = e.target.value;
  //   setValue1(inputValue);
  // };

  // ============UPLOAD FILES=====================//
  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://46.101.13.175:2222';

  const [loadingFile, setLoadingFile] = useState(false);
  const [fileError, setFileError] = useState();

  const uploadFileHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      alert('No File added!');
    } else {
      try {
        setLoadingFile(true);
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post(`${imageUrl}/upload`, formData);

        if (response.status === 200) {
          const { path } = response.data;
          // console.log('File uploaded successfully:', path);
          setFileError(null);
          setProduct({ ...product, imageUrl: [...product.imageUrl, path] });
        } else {
          console.error('File upload failed');
          // Handle error, e.g., show an error message or update UI accordingly
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setFileError(error);
      } finally {
        setLoadingFile(false);
      }
    }
  };
  // ============END UPLOAD FILES=================//
  //  =========MUTATION PRODUCT============//
  const [createProduct, { error, loading, data }] = useMutation(
    CREATE_PRODUCT,
    { refetchQueries: [{ query: GET_PRODUCTS }, { query: GET_MY_PRODUCT }] }
  );
  const handleOnSubmit = e => {
    product.productType = value1;
    if (product.qty) product.qty = parseInt(product.qty);
    if (product.minQty) product.minQty = parseInt(product.minQty);
    if (product.priceInEUR) product.priceInEUR = parseFloat(product.priceInEUR);
    if (product.priceInUSD) product.priceInUSD = parseFloat(product.priceInUSD);
    if (product.priceInBIF) product.priceInBIF = parseFloat(product.priceInBIF);
    createProduct({ variables: { ...product } }).catch(e => console.log(e));
    e.preventDefault();
  };
  //  =========MUTATION PRODUCT============//
  if (data) {
    navigate(`/dashboard/edit/${data.createProduct?.id}`);
  }
  return (
    <>
      <HStack>
        <Heading fontSize={'2xl'} mt={2} ml={0}>
          Create a Product
        </Heading>
      </HStack>
      <br />
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        // bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Container maxW={'lg'}>
          <Card mt="2">
            <CardBody>
              <Stack
                justify={'center'}
                // align="center"
                // direction={['column', 'row']}
                spacing={4}
                mb={5}
              >
                <MultipleUploading
                  fileError={fileError}
                  loadingFile={loadingFile}
                  uploadFileHandler={uploadFileHandler}
                  setProduct={setProduct}
                  product={product}
                />

                <FormControl id="option">
                  <FormLabel>Product Type</FormLabel>
                  <RadioGroup
                    defaultValue={'simple'}
                    name="option"
                    _placeholder={{ color: 'gray.500' }}
                    // onChange={() => (setValue1, console.log(value1))}
                    // value={value}
                  >
                    <Stack direction="row">
                      <Radio
                        colorScheme="green"
                        value="simple"
                        // onChange={handleOnChangeRadio}
                        onChange={(e, value) => setValue1('simple')}
                      >
                        Simple
                      </Radio>
                      <Radio
                        colorScheme="blue"
                        value="variation"
                        // onChange={handleOnChangeRadio}
                        onChange={(e, value) => setValue1('variation')}
                      >
                        Variation
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl id="Name">
                  <FormLabel>Name</FormLabel>
                  <Input
                    name="name"
                    placeholder="chocolat"
                    _placeholder={{ color: 'gray.500' }}
                    type="text"
                    onChange={handleOnChange}
                  />
                </FormControl>
                <FormControl id="category">
                  <FormLabel>Category:</FormLabel>
                  <Select
                    defaultValue="BIF"
                    name="category"
                    onChange={handleOnChange}
                  >
                    <option>Choose category</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Computers">Computers</option>
                    <option value="CCTV Surveillance Camera">
                      CCTV (Surveillance Camera)
                    </option>
                    <option value="Fiber Optic Items">Fiber Optic Items</option>
                    <option value="Fire Detection Systems">
                      Fire Detection Systems
                    </option>
                    <option value="Printers">Printers</option>
                    <option value="Accessories">Accessories</option>
                    <option value="Networking">Networking</option>
                  </Select>
                </FormControl>

                <FormControl id="description">
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    name="description"
                    placeholder="good quality, better price"
                    _placeholder={{ color: 'gray.500' }}
                    // type="text"
                    onChange={handleOnChange}
                  />
                </FormControl>

                {value1 === 'simple' ? (
                  <>
                    <FormControl id="qty">
                      <FormLabel>Quantity</FormLabel>
                      <Input
                        name="qty"
                        placeholder="50"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        onChange={handleOnChange}
                      />
                    </FormControl>

                    <VStack direction={['row', 'row']} spacing="24px">
                      <FormControl id="priceInBIF">
                        <FormLabel>Price In BIF</FormLabel>
                        <InputGroup>
                          <Input
                            name="priceInBIF"
                            placeholder="5000"
                            _placeholder={{ color: 'gray.500' }}
                            type="text"
                            onChange={handleOnChange}
                          />
                          <InputRightAddon>BIF</InputRightAddon>
                        </InputGroup>
                      </FormControl>
                      <FormControl id="priceInUSD">
                        <FormLabel>Price In USD</FormLabel>
                        <InputGroup>
                          <Input
                            name="priceInUSD"
                            placeholder="5000"
                            _placeholder={{ color: 'gray.500' }}
                            type="text"
                            onChange={handleOnChange}
                          />
                          <InputRightAddon>USD</InputRightAddon>
                        </InputGroup>
                      </FormControl>
                      <FormControl id="priceInEUR">
                        <FormLabel>Price In EUR</FormLabel>
                        <InputGroup>
                          <Input
                            name="priceInEUR"
                            placeholder="5000"
                            _placeholder={{ color: 'gray.500' }}
                            type="text"
                            onChange={handleOnChange}
                          />
                          <InputRightAddon>EUR</InputRightAddon>
                        </InputGroup>
                      </FormControl>
                    </VStack>

                    <FormControl id="minQty">
                      <FormLabel>Stock Alert</FormLabel>
                      <Input
                        name="minQty"
                        placeholder="20"
                        type="text"
                        onChange={handleOnChange}
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <FormControl id="minQty">
                      <FormLabel>Stock Alert</FormLabel>
                      <Input
                        name="minQty"
                        placeholder="20"
                        type="text"
                        onChange={handleOnChange}
                      />
                    </FormControl>
                    <FormControl id="title">
                      <FormLabel>Option Name</FormLabel>
                      <Input
                        name="title"
                        placeholder="ex: Storage RAM CPU"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        onChange={handleOnChange}
                      />
                    </FormControl>
                  </>
                )}

                <Stack spacing={6} direction={['column', 'row']}>
                  <Button
                    isLoading={loading}
                    bg={'brand.primary'}
                    color={'white'}
                    onClick={handleOnSubmit}
                    w="full"
                    _hover={{
                      bg: 'brand.hoverPrimary',
                    }}
                    loadingText="Submitting"
                    variant="outline"
                    mt={'2'}
                  >
                    Submit
                  </Button>
                </Stack>
                {!error ? <></> : <Error data={error} />}
              </Stack>
            </CardBody>
          </Card>
        </Container>
      </Flex>
    </>
  );
}

export default CreateProducts;
