import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import DeleteWarning from '../DeleteWarning';
import { Timer2, findMax, findMin } from '../../config/functions';

function Table1({ data, endDate, startDate, search }) {
  let navigate = useNavigate();
  const [isShow, setShow] = useState(false);
  const [isDeleteId, setDeleteId] = useState(false);
  const [name, setName] = useState();
  const handleShow = msg => {
    setShow(!msg);
  };

  return (
    <>
      <DeleteWarning
        setOpen={setShow}
        isOpen={isShow}
        data={{
          operation: 'product',
          title: isDeleteId,
          status: null,
          name: name,
        }}
      />

      <div>
        <TableContainer fontSize={'sm'}>
          <Table variant="striped" colorScheme="teal" size={'sm'} id="TOrders">
            <Thead>
              <Tr>
                <Th>ID Procuct</Th>
                <Th>Product Name</Th>
                {/* <Th>Owner</Th> */}
                <Th>Catégory</Th>
                <Th>Quantity</Th>
                <Th>Price</Th>
                {/* <Th>Created At</Th> */}
                {/* <Th>Total</Th> */}

                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data
                ?.filter(val => {
                  let product;
                  if (search === '') {
                    product = val;
                  } else if (
                    val.name?.toLowerCase().includes(search.toLowerCase())
                  ) {
                    product = val;
                  } else if (
                    val.category?.toLowerCase().includes(search.toLowerCase())
                  ) {
                    product = val;
                  } else if (
                    val.productCode
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    product = val;
                  }
                  return product;
                })
                ?.filter(val => {
                  let orderFiltered;
                  let endDateObject = endDate ? new Date(endDate) : null;
                  // Add one day to endDate if it is not null
                  if (endDateObject) {
                    endDateObject.setDate(endDateObject.getDate() + 1);
                  }
                  if (endDate && startDate) {
                    if (
                      val.createdAt >= new Date(startDate).getTime() &&
                      val.createdAt <= new Date(endDateObject).getTime()
                    ) {
                      orderFiltered = val;
                    }
                  } else {
                    orderFiltered = val;
                  }
                  return orderFiltered;
                })
                ?.map(val => (
                  <Tr cursor={'pointer'} key={val.id}>
                    <Td>{val.productCode}</Td>
                    <Td>{val.name}</Td>

                    <Td>{val.category}</Td>
                    <Td>
                      {val.productType === 'simple'
                        ? val.qty
                        : val.options.variations.reduce(
                            (acc, curr) => acc + curr.qty,
                            0
                          )}
                    </Td>
                    <Td>
                      {val.productType === 'simple' ? (
                        <>
                          {val.priceInBIF.toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'BIF',
                          })}
                        </>
                      ) : (
                        <>
                          from{' '}
                          {findMin(
                            val?.options?.variations,
                            'BIF'
                          )?.toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'BIF',
                          })}
                        </>
                      )}
                    </Td>

                    <Td>
                      <ButtonGroup gap={'2'}>
                        <Button
                          size={'sm'}
                          onClick={() => navigate(`/dashboard/edit/${val.id}`)}
                          color={'white'}
                          bg={'brand.primary'}
                          _hover={{ bg: 'brand.hoverPrimary' }}
                        >
                          Details
                        </Button>
                        {/* <Button
                          size={'sm'}
                          onClick={() => navigate(`/dashboard/edit/${val.id}`)}
                          color={'white'}
                          bg={'brand.primary'}
                          _hover={{ bg: 'brand.hoverPrimary' }}
                        >
                          Edit
                        </Button> */}
                        <Button
                          size={'sm'}
                          onClick={() => {
                            setDeleteId(val.id);
                            setName(val.name);
                            handleShow();
                          }}
                          color={'white'}
                          bg={'red.400'}
                          _hover={{ bg: 'red.600' }}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Table1;
