import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Badge,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import DeleteWarning from '../DeleteWarning';
import ChangeStatusModal from './ChangeStatusModal';
import { Timer2 } from '../../config/functions';

function Table2({ data, startDate, endDate, search }) {
  let navigate = useNavigate();
  const [isShow, setShow] = useState(false);
  const [isShow1, setShow1] = useState(false);
  const [isDeleteId, setDeleteId] = useState(false);
  const [isStatus, setStatus] = useState(false);
  const handleShow = msg => {
    setShow(!msg);
  };
  const handleShow1 = msg => {
    setShow1(!msg);
  };

  return (
    <>
      <DeleteWarning
        setOpen={setShow}
        isOpen={isShow}
        data={{
          operation: 'order',
          title: isDeleteId,
        }}
      />
      <ChangeStatusModal
        setOpen={setShow1}
        isOpen={isShow1}
        data={{
          title: isDeleteId,
          status: isStatus,
        }}
      />

      <div>
        <TableContainer fontSize={'sm'}>
          <Table variant="striped" colorScheme="teal" size={'sm'} id="TOrders">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>ID Order</Th>
                <Th>Amount</Th>
                <Th>paymentMethod</Th>
                <Th>Status</Th>
                <Th>ID Transaction</Th>
                {/* <Th>Seller</Th> */}
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.orders
                ?.filter(val => {
                  let order;
                  if (search === '') {
                    order = val;
                  } else if (
                    val.transactionId
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    order = val;
                  } else if (
                    val.orderId?.toLowerCase().includes(search.toLowerCase())
                  ) {
                    order = val;
                  }
                  return order;
                })
                ?.filter(val => {
                  let orderFiltered;
                  let endDateObject = endDate ? new Date(endDate) : null;
                  // Add one day to endDate if it is not null
                  if (endDateObject) {
                    endDateObject.setDate(endDateObject.getDate() + 1);
                  }
                  if (endDate && startDate) {
                    if (
                      val.createdAt >= new Date(startDate).getTime() &&
                      val.createdAt <= new Date(endDateObject).getTime()
                    ) {
                      orderFiltered = val;
                    }
                  } else {
                    orderFiltered = val;
                  }
                  return orderFiltered;
                })
                ?.map(val => (
                  <Tr cursor={'pointer'} key={val.id}>
                    <Td>{Timer2(val.createdAt)}</Td>
                    <Td>{val.orderId}</Td>

                    <Td>
                      {val.total.toLocaleString('fr-FR', {
                        /* style: 'currency', */
                        /* currency: '{val.currency}', */
                      })}{' '}
                      {val.currency}
                    </Td>

                    <Td>{val.paymentMethod}</Td>
                    <Td>
                      {val.status === 'pending' ? (
                        <Badge colorScheme="green">pending</Badge>
                      ) : val.status === 'deleted' ? (
                        <Badge colorScheme="red">deleted</Badge>
                      ) : val.status === 'processing' ? (
                        <Badge colorScheme="blue">processing</Badge>
                      ) : val.status === 'completed' ? (
                        <Badge colorScheme="purple">completed</Badge>
                      ) : val.status === 'paid' ? (
                        <Badge>paid</Badge>
                      ) : val.status === 'pending payment' ? (
                        <Badge colorScheme="yellow">pending payment</Badge>
                      ) : (
                        <></>
                      )}
                    </Td>
                    <Td>{val.transactionId}</Td>
                    {/* <Td>{val.createdBy.name}</Td> */}
                    <Td>
                      <ButtonGroup gap={'2'}>
                        {' '}
                        <Button
                          size={'sm'}
                          onClick={() =>
                            navigate(`/dashboard/orders/${val.id}`)
                          }
                          color={'white'}
                          bg={'brand.primary'}
                          _hover={{ bg: 'brand.hoverPrimary' }}
                        >
                          Details
                        </Button>
                        <Button
                          size={'sm'}
                          onClick={() => {
                            setDeleteId(val.id);
                            setStatus(val.status);
                            handleShow1();
                          }}
                          color={'white'}
                          colorScheme="teal"
                        >
                          Change
                        </Button>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Table2;
