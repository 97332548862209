import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb,
  Container,
  Center,
  Avatar,
  VStack,
  Text,
  Image,
  Heading,
  Select,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { GET_ALL_USERS, GET_ME_USER, UPDATE_USER } from '../config/graphql';
import Error from '../components/Error';
import Loading from '../components/Loading';
import { useMutation, useQuery } from '@apollo/client';
import { isValidEmail } from '../config/validations';
import axios from 'axios';

const Account = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const localUser = JSON.parse(localStorage.getItem('user'));
  const id = localUser?.userId;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { data, error, loading } = useQuery(GET_ME_USER);
  const [updateUser, { error: errorUpdate, loading: loadingUpdate }] =
    useMutation(UPDATE_USER, { refetchQueries: { query: GET_ALL_USERS } });

  const handleOnChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  // ============UPLOAD FILES=====================
  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';

  const [loadingFile, setLoadingFile] = useState(false);
  const [fileError, setFileError] = useState();

  const uploadFileHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      alert('No File added!');
    } else {
      try {
        setLoadingFile(true);
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post(`${imageUrl}/upload`, formData);

        if (response.status === 200) {
          const { path } = response.data;
          console.log('File uploaded successfully:', path);
          setFileError(null);
          setUser({ ...user, imageUrl: path });
        } else {
          console.error('File upload failed');
          setFileError('500: Server error!');
          // Handle error, e.g., show an error message or update UI accordingly
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setFileError(error);
      } finally {
        setLoadingFile(false);
      }
    }
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error data={error} />;
  }

  // ============END UPLOAD FILES=================

  // const handleImageUpload = e => {
  //   const file = e.target.files[0];
  //   const imageUrl = URL.createObjectURL(file);
  //   setUser(prevData => ({ ...prevData, imageUrl }));
  // };

  const handleOnSubmit = event => {
    if (user.email) {
      if (!isValidEmail(user.email)) {
        alert('Invalid Email!');
      } else {
        user.id = id;
        updateUser({
          variables: { ...user },
          refetchQueries: [{ query: GET_ME_USER }],
        })
          .then(data => {
            alert('Successfully Updated!');
          })
          .catch(e => console.log(e));
      }
    } else {
      user.id = id;
      updateUser({
        variables: { ...user },
        refetchQueries: [{ query: GET_ME_USER }],
      })
        .then(data => {
          alert('Successfully Updated!');
        })
        .catch(e => console.log(e));
    }

    event.preventDefault();
  };

  if (data) {
    const userDb = data.meUser;
    return (
      <Box p={4} shadow="md" borderRadius="md" bg="white">
        <Container maxWidth={'container.xl'}>
          <Heading size={'md'}>My Account Informations</Heading>
          <br />
          <Center>
            <Stack
              spacing={4}
              w={'full'}
              maxW={'lg'}
              rounded={'xl'}
              boxShadow={'lg'}
              p={6}
            >
              <FormControl id="imageUrl">
                <FormLabel>Profile Picture</FormLabel>
                <Stack direction={['column', 'row']} spacing={6}>
                  <Center>
                    <VStack>
                      {user.imageUrl ? (
                        <Avatar size="xl" src={imageUrl + user?.imageUrl} />
                      ) : (
                        <Avatar size="xl" src={imageUrl + userDb?.imageUrl} />
                      )}

                      {fileError ? <Text>{fileError.message}</Text> : <></>}
                    </VStack>
                  </Center>
                  <Center w="full">
                    <VStack>
                      <Input
                        isDisabled={loadingFile}
                        pt={1}
                        accept="image/*"
                        onChange={uploadFileHandler}
                        name="imageUrl"
                        type="file"
                      />
                    </VStack>
                  </Center>
                </Stack>
              </FormControl>
              <FormControl id="name">
                <FormLabel>Full Name</FormLabel>
                <Input
                  defaultValue={userDb?.name}
                  onChange={handleOnChange}
                  name="name"
                  placeholder="ex: Arsene Nduwayo"
                  type="text"
                />
              </FormControl>
              <FormControl id="phone">
                <FormLabel>Phone number</FormLabel>
                <Input
                  defaultValue={userDb?.phone}
                  onChange={handleOnChange}
                  name="phone"
                  placeholder="ex: +25762525242"
                  type="text"
                />
              </FormControl>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  placeholder="your-email@example.com"
                  defaultValue={userDb?.email}
                  onChange={handleOnChange}
                  name="email"
                  type="email"
                />
              </FormControl>
              <FormControl id="country">
                <FormLabel>Country</FormLabel>
                <Input
                  placeholder="Burundi"
                  defaultValue={userDb?.country}
                  onChange={handleOnChange}
                  name="country"
                  type="text"
                />
              </FormControl>
              <FormControl id="city">
                <FormLabel>City/Province/State</FormLabel>
                <Input
                  name="city"
                  placeholder="Bujumbura"
                  defaultValue={userDb?.city}
                  onChange={handleOnChange}
                  type="text"
                />
              </FormControl>
              <FormControl id="address">
                <FormLabel>Address</FormLabel>
                <Input
                  name="address"
                  placeholder="Kinanira av Itaba No 4"
                  defaultValue={userDb?.address}
                  onChange={handleOnChange}
                  type="text"
                />
              </FormControl>
              <FormControl id="birthdate">
                <FormLabel>Birthdate</FormLabel>
                <Input
                  name="birthdate"
                  placeholder=""
                  defaultValue={userDb?.address}
                  onChange={handleOnChange}
                  type="date"
                />
              </FormControl>
              <FormControl id="gender">
                <FormLabel>Gender</FormLabel>
                <Select
                  name="gender"
                  onChange={handleOnChange}
                  default={'choose your gender'}
                >
                  <option>choose your gender'</option>
                  <option value={'male'}>male</option>
                  <option value={'female'}>female</option>
                </Select>
              </FormControl>

              <br />
              <Stack spacing={6} direction={['column', 'row']}>
                <Button
                  onClick={handleOnSubmit}
                  isLoading={loadingUpdate}
                  loadingText="Updating"
                  spinnerPlacement="end"
                  w="full"
                  bg={'brand.primary'}
                  color={'white'}
                  _hover={{ bg: 'brand.hoverPrimary' }}
                >
                  Update
                </Button>
              </Stack>
              {errorUpdate ? <Error data={errorUpdate} /> : <></>}
            </Stack>
          </Center>
        </Container>
      </Box>
    );
  }
};

export default Account;
