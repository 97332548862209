import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Success(props) {
  const { title, description, link } = props.data;
  const [isOpen, setOpen] = useState(true);
  const { onClose } = useDisclosure();

  const navigate = useNavigate();

  return (
    <>
      <Modal
        size={{ base: 'sm' }}
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
          navigate(link);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{description}</ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setOpen(false);
                navigate(link);
              }}
            >
              Close
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
