import { useMutation } from '@apollo/client';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Spinner,
  FormLabel,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error from '../components/Error';
import Success from '../components/Success';
import { PASSWORD_REST } from '../config/graphql';

export default function PasswordReset() {
  const { userId, token } = useParams();

  const [user, setUser] = useState();

  const [resetPassord, { error, loading }] = useMutation(PASSWORD_REST);

  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShow = e => {
    setShowModal(!showModal);
  };

  const handleOnChange3 = e => {
    if (e.target.name === 'password') {
      document.getElementById('cpassword').value = '';
      // setUser({ ...user, password: null });
    } else {
      const password = document.getElementById('password').value;
      const cPassword = document.getElementById('cpassword').value;

      if (password === cPassword) {
        setValidationError('');
        setUser({ ...user, password: e.target.value });
        // setValidationError("Password does not match!");
      }
    }
  };

  const handleOnChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleOnSubmit = event => {
    // VALIDATE PASSWORD
    const password = document.getElementById('password').value;
    const cPassword = document.getElementById('cpassword').value;

    if (password !== cPassword) {
      setValidationError('Password does not match!');
    } else {
      setValidationError('');
      user.userId = userId;
      user.token = token;
      resetPassord({ variables: { ...user } })
        .then(data => handleShow())
        .catch(e => console.log(e));
    }
    event.preventDefault();
  };

  return (
    <Flex
      //   minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      {showModal ? (
        <>
          <Success
            data={{
              title: 'Success',
              link: '/signin',
              description: 'Password reset Successfully!',
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Reset Password
        </Heading>
        {/* <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}
        >
          You&apos;ll get an email with a reset link
        </Text> */}
        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              onChange={handleOnChange}
              name="password"
              type={showPassword ? 'text' : 'password'}
            />
            <InputRightElement h={'full'}>
              <Button
                variant={'ghost'}
                onClick={() => setShowPassword(showPassword => !showPassword)}
              >
                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl id="cpassword" isRequired>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <Input
              name="cpassword"
              onChange={handleOnChange3}
              type={showPassword ? 'text' : 'password'}
            />
            <InputRightElement h={'full'}>
              <Button
                variant={'ghost'}
                onClick={() => setShowPassword(showPassword => !showPassword)}
              >
                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Stack spacing={6}>
          <Button
            disabled={loading}
            onClick={handleOnSubmit}
            bgGradient="linear(to-r, brand.primaryLight, blue.600)"
            color={'white'}
            _hover={{
              bg: 'brand.hoverPrimary',
            }}
          >
            {loading ? <Spinner /> : 'Request Reset'}
          </Button>
        </Stack>
        {error ? <Error error={error} /> : <></>}
        {validationError !== '' ? (
          <Error error={{ message: validationError }} />
        ) : (
          <></>
        )}
      </Stack>
    </Flex>
  );
}
