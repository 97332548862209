const regex = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,16})/,
  // password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16})/,
  phone: /^[0-9]*$/,
};

const isValidEmail = v => {
  return !!v && typeof v === 'string' && v.length > 4 && regex.email.test(v);
};
export { isValidEmail };

const isValidPassword = v => {
  return !!v && typeof v === 'string' && regex.password.test(v);
};
export { isValidPassword };

// todo add more phone validation
const isValidPhone = phone => {
  return phone && phone.length === 8 && regex.phone.test(phone);
};

export { isValidPhone };
