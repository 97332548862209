import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Dashboard from './pages/Dashboard';
import Stats from './components/stats/Stats';
import Orders from './components/orders/Orders';
import CreateProducts from './components/products/CreateProducts';
import OrderDetails from './components/orders/OrderDetails';
import ListUsers from './components/users/ListUsers';
import EditProduct from './components/products/EditProduct';
import UserDetails from './components/users/UserDetails';
import Login from './pages/Login';
import Signup from './pages/Signup';
// import Loading3 from './components/Loading3';
import Account from './pages/Account';
import PasswordChange from './pages/PasswordChange';
import ProtectedRoutes, { ProtectedRouteLogin } from './config/ProtectedRoutes';
import ProductsDivide from './pages/ProductsDivide';
import Forgot from './pages/Forgot-password';
import PasswordReset from './pages/PasswordReset';

// EXTENDED COLORS
const colors = {
  brand: {
    primary: '#204998',
    hoverPrimary: '#3B60A6',
    table: 'teal', // must put the color on hexcode
  },
};

const theme = extendTheme({
  colors,
});

function App() {
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   });
  // }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/signup"
            element={
              <ProtectedRouteLogin>
                <Signup />
              </ProtectedRouteLogin>
            }
          />
          <Route
            exact
            path="/"
            element={
              <ProtectedRouteLogin>
                <Login />
              </ProtectedRouteLogin>
            }
          />
          <Route
            exact
            path="/reset/:userId/:token"
            element={<PasswordReset />}
          />
          <Route
            exact
            path="/forgot-password"
            element={
              <ProtectedRouteLogin>
                <Forgot />
              </ProtectedRouteLogin>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          >
            <Route index element={<ProductsDivide />} />
            <Route exact path="products/create" element={<CreateProducts />} />
            <Route exact path="edit/:id" element={<EditProduct />} />
            <Route exact path="orders" element={<Orders />} />
            <Route exact path="orders/:id" element={<OrderDetails />} />
            <Route exact path="users" element={<ListUsers />} />
            <Route exact path="users/:id" element={<UserDetails />} />
            <Route exact path="stats" element={<Stats />} />
            <Route exact path="account" element={<Account />} />
            <Route exact path="change-password" element={<PasswordChange />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
