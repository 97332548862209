import { useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Error from '../components/Error';
import Success from '../components/Success';
import { SEND_RESET_LINK } from '../config/graphql';

export default function Forgot() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [user, setUser] = useState();

  const [sendResetLink, { error, loading }] = useMutation(SEND_RESET_LINK);

  const [showModal, setShowModal] = useState(false);

  const handleShow = e => {
    setShowModal(!showModal);
  };

  const handleOnChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleOnSubmit = event => {
    if (!user.email) {
      alert('No email provided!');
    } else {
      sendResetLink({ variables: { ...user } })
        .then(data => handleShow())
        .catch(e => console.log(e));
    }
    event.preventDefault();
  };

  return (
    <Flex
      minH={'80vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      {showModal ? (
        <>
          <Success
            data={{
              title: 'Success',
              link: '/',
              description:
                'If there is a user with this email, a link will be sent!',
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Stack
        spacing={4}
        w={'full'}
        maxW={'sm'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Forgot your password?
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}
        >
          You&apos;ll get an email with a reset code
        </Text>
        <FormControl id="email">
          <Input
            name="email"
            onChange={handleOnChange}
            placeholder="your-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="email"
          />
        </FormControl>
        <Stack spacing={6}>
          <Button
            onClick={handleOnSubmit}
            bg={'brand.primary'}
            color={'white'}
            w={'full'}
            _hover={{ bg: 'brand.hoverPrimary' }}
          >
            {loading ? <Spinner /> : 'Request Reset'}
          </Button>
        </Stack>
        {error ? <Error error={error} /> : <></>}
      </Stack>
    </Flex>
  );
}
